import $store from '@/store'
import $http from '@/libs/api';
import $router from '@/router'
import $api from '@/router/rotasAPI'
import $helpers from '@/helpers';
import { getUserData } from "@/auth/utils";
import { getNomeCategoriaNomeIncentivo, handleRetornoApiPutPost, retornaMensagemSucessoPedidoAdesao } from '@/store/adesaoState/functions.js'
import moment from 'moment';

const MINUTOS_CONTADOR_ADESAO_EMPRESA = 15
const tipoColaboradores = ["trabalhador", "dependente", "terceiro"];

// getters vuex
export function AdesaoGetChooseCampaign() {
    return $store.getters["adesaoState/getChooseCampaign"];
}

export function AdesaoGetChooseUnit() {
    return $store.getters["adesaoState/getChooseUnit"];
}

export function AdesaoGetChooseCompany() {
    return $store.getters["adesaoState/getChooseCompany"];
}

export function AdesaoGetVacinas() {
    return $store.getters["adesaoState/getVacinas"];
}

export function AdesaoGetEmpresas() {
    return $store.getters["adesaoState/getEmpresas"];
}

export function AdesaoGetResumoVacinas() {
    return $store.getters["adesaoState/getResumoVacinas"];
}

export function AdesaoGetDadosPost(rascunho) {
    return $store.getters["adesaoState/getDadosPost"](rascunho);
}

export function AdesaoGetInformacoesPersistencia() {
    return $store.getters["adesaoState/getInformacoesPersistencia"];
}

export function AdesaoGetVacinasDaEmpresa(empresa) {
    let index = $helpers.findIndexInArray(AdesaoGetEmpresas(), empresa, 'id_empresa')
    return AdesaoGetEmpresas()[index].vacinas.filter((vacina) => vacina.id_tipo_preco === empresa.id_tipo_preco_empresa);
}

export function AdesaoGetObservacoesDasEmpresas() {
    return $store.getters["adesaoState/getObservacoesDasEmpresas"];
}

export function AdesaoHandleVisibilidadeObservacaoModal(empresa = null) {
    $store.dispatch("adesaoState/handleVisibilidadeObservacaoModal", empresa);
}

export function AdesaoGetDesabilitarEnvio() {
    let naoTemEmpresas = AdesaoGetDadosPost().empresas.length == 0;
    return $store.getters["adesaoState/getDesabilitarEnvio"] || naoTemEmpresas;
}

export function AdesaoGetTipoDeIncentivo(estimativa) {
    return $helpers.ehDoTipoComFomento(estimativa.id_incentivo) ?
        "com_fomento" :
        "sem_fomento";
}

export function AdesaoGetEhTipoDaEstimativa(estimativa, categoria) {
    return estimativa.id_categoria === categoria.id_categoria;
}

export async function AdesaoInit(isReset = false) {
    $store.dispatch("adesaoState/initAdesao", getUserData().id, isReset);
}

export async function AdesaoReset() {
    $store.dispatch("adesaoState/resetAdesao");
}

export async function AdesaoSetVacinas(chooseCampaign, chooseUnit) {
    await $http
        .get(
            $api.estimativaCampanhaVacinaUnidade(
                chooseCampaign.id_campanha,
                chooseUnit.id_unidade
            )
        )
        .then((response) => {
            $store.dispatch("adesaoState/setVacinas", response.data);
            SetEmpresaDosesVacinas();
        })
}

export async function AdesaoLimparCamposDosesVacinasDaEmpresa(empresa, vacina) {
    $store.dispatch("adesaoState/limparCamposDosesVacinas", {
        empresa,
        vacina,
    });
}

export async function AdesaoRemoverEmpresa(empresa) {
    AdesaoGetVacinasDaEmpresa(empresa).forEach((vacina) => {
        AdesaoLimparCamposDosesVacinasDaEmpresa(empresa, vacina);
    });
    $store.dispatch("adesaoState/deleteEmpresa", empresa);
}

export async function AdesaoSetDoseVacina(empresa, vacina, campo, tipo, value, valor, id_pedido_vacina = null) {
    $store.dispatch("adesaoState/updateDose", {
        empresa,
        vacina,
        campo,
        tipo,
        value,
        valor,
        id_pedido_vacina
    });
}

export async function AdesaoAtualizarEmpresasSequencia(empresas) {
    $store.dispatch("adesaoState/updateEmpresasSequencia", { empresas });
}

export async function SetEmpresaDosesVacinas() {
    let rota = $api.campanhaTipoPreco(
        AdesaoGetChooseCampaign().id_campanha,
        AdesaoGetVacinas().map((vacina) => vacina.id_vacina)
    );

    let vacinasNomes = {};
    await $http
        .get(rota)
        .then((response) => {
            let vacinas = response.data;

            AdesaoGetVacinas().forEach((vacinaProp) => {
                vacinasNomes[vacinaProp.id_vacina] = vacinaProp.nome_tecnico;
                vacinas = vacinas.map((vacina) => {
                    vacina.nome_tecnico = vacinasNomes[vacina.id_vacina];
                    return vacina;
                });

                const indices = vacinas.map((vacina, index) => vacina.id_vacina === vacinaProp.id_vacina ? index : '').filter(String);

                vacinaProp.estimativas.forEach((estimativa) => {
                    let tipoCategoria = AdesaoGetTipoDeIncentivo(estimativa);

                    tipoColaboradores.forEach((tipoColaborador) => {
                        indices.forEach((indexVacina) => {
                            const ehTipoDaEstimativa = AdesaoGetEhTipoDaEstimativa(
                                estimativa,
                                vacinas[indexVacina].doses[tipoCategoria][tipoColaborador]
                            );

                            if (ehTipoDaEstimativa) {
                                vacinas[indexVacina].doses[tipoCategoria][tipoColaborador].qtdDisponivel = estimativa.quantidade;
                                vacinas[indexVacina].doses[tipoCategoria][tipoColaborador].id_incentivo = estimativa.id_incentivo
                            }
                        })
                    });
                });
            });

            $store.dispatch("adesaoState/initEmpresasDoses", vacinas);
        })
}

export async function AdesaoGetApiEmpresasParaAdesao() {
    let year = moment(AdesaoGetChooseCampaign().termino_campanha).format('YYYY')

    if(moment().year() == moment(AdesaoGetChooseCampaign().inicio_campanha).format('YYYY')) {
      year = moment(AdesaoGetChooseCampaign().inicio_campanha).format('YYYY')
    }

    return $http
        .get($api.empresaAdesao(AdesaoGetChooseUnit().id_unidade, AdesaoGetVacinas()), {
            params: {
                anoColaboradores: year,
                idCampanha: $store.getters['adesaoState/getChooseCampaign'].id_campanha,
                situacaoEmpresa: $store.getters['adesaoState/getChooseCampaign'].permite_adesao_parcial ? ['Completo', 'Parcial'] : null,
            }
        })
        .then((response) => response.data)
}

export async function AdesaoAdicionarEmpresaSesi(empresa, infoDoses) {
    let year = moment(AdesaoGetChooseCampaign().termino_campanha).format('YYYY')

    if(moment().year() == moment(AdesaoGetChooseCampaign().inicio_campanha).format('YYYY')) {
      year = moment(AdesaoGetChooseCampaign().inicio_campanha).format('YYYY')
    }

    $http.get($api.campanhaEmpresaParameter(empresa.id_empresa), {
            params: {
                ano: year,
                id_campanha: $store.getters['adesaoState/getChooseCampaign'].id_campanha
            }
        })
        .then(({ data }) => {
            const campanhas = data;
            if (campanhas) {
                campanhas.forEach((campanha, index) => {
                    let qtdTrabalhador = empresa.trabalhador -= parseInt(campanha.quantidade_categoria.Trabalhador) || 0,
                        qtdDependente = empresa.dependente -= parseInt(campanha.quantidade_categoria.Dependente) || 0,
                        qtdTerceiro = empresa.terceiro -= parseInt(campanha.quantidade_categoria.Terceiro) || 0;

                        empresa.trabalhador = qtdTrabalhador > 0 ? qtdTrabalhador : 0;
                        empresa.dependente = qtdDependente > 0 ? qtdDependente : 0;
                        empresa.terceiro = qtdTerceiro > 0 ? qtdTerceiro : 0;
                    });
                }
            $store.dispatch("adesaoState/addEmpresa", empresa);
            AdesaoUpdateEmpresaDoses(infoDoses);
        });
}

export async function AdesaoAdicionarEmpresa(empresa) {
    $store.dispatch("adesaoState/addEmpresa", empresa);
}

export async function AdesaoDescartarPedido(modoCriacao = true) {
    let name = modoCriacao ? "join-campaign" : "acceptances";
    $store.dispatch("adesaoState/initAdesao");
    $router.push({ name }).catch(error => {
        if (error.name != "NavigationDuplicated") {
            throw error;
        }
    });
}

export async function AdesaoUpdateObservacaoEmpresa(empresa, observacao) {
    $store.dispatch("adesaoState/updateEmpresaObservacao", { empresa, observacao });
    AdesaoHandleVisibilidadeObservacaoModal(null);
}

export function AdesaoSetInformacoesPersistencia(status, mensagens) {
    $store.dispatch("adesaoState/setInformacoesPersistencia", { status, mensagens });
}

export async function AdesaoSalvarPedido(rascunho, ehEmpresa = false) {
    let rota = !ehEmpresa ? $api.pedido() : $api.adesao();
    await $http.post(rota, AdesaoGetDadosPost(rascunho))
        .then((response) => {
            AdesaoSetInformacoesPersistencia('success', [retornaMensagemSucessoPedidoAdesao(rascunho, ehEmpresa)])
        }).catch(({ response: { data } }) => {
            AdesaoSetInformacoesPersistencia('error', handleRetornoApiPutPost(data.errors))
        });
}

export async function AdesaoSalvarPedidoRascunho(rascunho) {
    $http.put($api.pedido(), AdesaoGetDadosPost(rascunho))
        .then((response) => {
            AdesaoSetInformacoesPersistencia('success', [retornaMensagemSucessoPedidoAdesao(rascunho, false)])
        }).catch(({ response: { data } }) => {
        AdesaoSetInformacoesPersistencia('error', handleRetornoApiPutPost(data.errors))
    });

}

export function AdesaoSetChooseCampaign(campaign) {
    $store.dispatch("adesaoState/setChooseCampaign", campaign);
}

export function AdesaoSetChooseUnit(unit) {
    $store.dispatch("adesaoState/setChooseUnit", unit);
}

export function AdesaoGetChooseOrder() {
    return $store.getters["adesaoState/getChooseOrder"];
}

export async function AdesaoSetChooseOrder(order = null) {

    $store.dispatch("adesaoState/setChooseOrder", order);

    if (order != null) {
        AdesaoSetDependencesOrder(order, "editar-pedido");
    }
}

export async function AdesaoSetChooseOrderAdhesion(order) {
    $store.dispatch("adesaoState/setChooseOrder", order);
    AdesaoSetDependencesOrder(order, "visualizar-adesao-empresa");
}

export async function AdesaoSetDependencesOrder(order, redirectTo) {
    await $http.get($api.pedidoId(order.id_pedido))
        .then(({ data }) => {
            AdesaoInit();
            AdesaoSetChooseCampaign(data.campanha)
            AdesaoSetChooseUnit(data.unidade)
            AdesaoSetVacinas(data.campanha, data.unidade)
            Object.values(data.empresas).forEach((empresa) => {
                AdesaoAdicionarEmpresaSesi(empresa, data);
            });
            $router.push({ name: redirectTo }).catch(error => {
                if (error.name != "NavigationDuplicated") {
                    throw error;
                }
            });
        })
        .catch(() => { });
}

export async function AdesaoSetChooseObservacao(empresa) {
    $store.dispatch("adesaoState/setChooseObservacao", empresa);
}

export async function AdesaoUpdateEmpresaDoses(data) {
    if (data != undefined) {
        data.empresas.forEach((empresa) => {
            let pedidosEmpresas = $helpers.filterByValue(data.pedido.pedido_empresa, empresa, 'id_empresa'),
                stateEmpresas = AdesaoGetEmpresas(),
                indexEmpresa = $helpers.findIndexInArray(stateEmpresas, empresa, 'id_empresa')

            if (typeof stateEmpresas[indexEmpresa] !== 'undefined') {
                stateEmpresas[indexEmpresa].updatedAt = $helpers.geraNumeroAleatorio();

                pedidosEmpresas.forEach((pedidoEmpresa) => {
                    AdesaoSetChooseObservacao(pedidoEmpresa);
                    pedidoEmpresa.pedido_vacina.forEach((dose) => {

                        let campanhaVacinaUnidade = $helpers.filterByValue(data.campanha_vacina_unidade, dose, 'id_campanha_vacina_unidade')[0],
                            categoriaIncentivo = getNomeCategoriaNomeIncentivo(dose.id_categoria, campanhaVacinaUnidade.id_incentivo);

                        if (dose.quantidade > 0) {
                            AdesaoSetDoseVacina(
                                stateEmpresas[indexEmpresa], // empresa
                                campanhaVacinaUnidade, // vacina
                                categoriaIncentivo.nomeIncentivo, //campo
                                categoriaIncentivo.nomeCategoria, //tipo
                                dose.quantidade, //value
                                dose.valor, // valor
                                dose.id_pedido_vacina, // id_pedido_vacina
                            );
                        }
                    });
                });
            }
        })
    }
}

export function AdesaoGetDoseVacinaEmpresa({ tipagem, vacina, empresa }) {
    try {
        let empresas = AdesaoGetEmpresas(),
            indexEmpresa = $helpers.findIndexInArray(empresas, empresa, 'id_empresa'),
            vacinas = empresas[indexEmpresa].vacinas,
            indexVacina = $helpers.findIndexInArray(vacinas, vacina, 'id_vacina'),
            incentivoCategoria = tipagem.split('.'),
            incentivo = incentivoCategoria[0],
            categoria = incentivoCategoria[1],
            vacinaDaEmpresa = empresas[indexEmpresa].vacinas[indexVacina];

        return vacinaDaEmpresa.doses[incentivo][categoria].qtd;
    } catch (error) {
        return 0;
    }
}

export function AdesaoResetPersistencia() {
    $store.dispatch("adesaoState/resetPersistencia");
}

export function AdesaoResetDependences() {
    AdesaoReset()
    AdesaoResetPersistencia()
    AdesaoSetChooseOrder(null)
}

export function AdesaoSetValidacaoDosesEmpresa(payload, expira_em = false) {
    if (payload != null) {
        payload.expira_em = expira_em ? moment(Date.now()).add(MINUTOS_CONTADOR_ADESAO_EMPRESA, 'm').valueOf() : 0;
    }
    $store.dispatch("adesaoState/setValidacaoDosesEmpresa", payload);
}

export function AdesaoGetValidacaoDosesEmpresa() {
    return $store.getters["adesaoState/getValidacaoDosesEmpresa"];
}

export async function AdesaoValidarDosesPedido(rascunho) {
    await $http.post($api.adesaoPrecificacoes(), AdesaoGetDadosPost(rascunho))
        .then((response) => {
            AdesaoSetValidacaoDosesEmpresa(response.data, true)
            $router.push({ name: "detalhes-pedido" })
        })
        .catch(({ response: { data } }) => {
            AdesaoSetInformacoesPersistencia('error', handleRetornoApiPutPost(data.errors))
        });
}

export function AdesaoGetState() {
    return $store.getters["adesaoState/getState"];
}
export function getChooseCompanyAddress() {
    return $store.getters["adesaoState/getChooseCompanyAddress"];
}